import { GiftCard, GiftCardProps, NamedFontType } from '@inkd/ui';
import {
  KIOSK_ASSET_BASE_PATH,
  calcFontSizeVariant,
  messageClass,
  signatureClass,
} from '@inkd/utils';

export interface FinalPreviewProps {
  coverImageSrc?: string;
  coverImageAlt?: string;
  interiorImageSrc?: string;
  cardMessage?: string;
  cardSignature?: string;
  cardSignatureImg?: string;
  fontSelection: NamedFontType;
  cardPrice: number;
  giftCard?: Omit<GiftCardProps, 'onClick'> & { selectedDenomination: string };
  textFormat: string;
  textPosition: string;
}

export const FinalPreview = ({
  coverImageSrc,
  coverImageAlt,
  interiorImageSrc,
  cardMessage,
  cardSignature,
  cardSignatureImg,
  cardPrice,
  fontSelection = 'Modern',
  giftCard,
  textFormat,
  textPosition,
}: FinalPreviewProps) => {
  return (
    <>
      <div className='kiosk:gap-12 web:gap-6 flex max-w-[792px] flex-col'>
        <div className='grid w-full grid-cols-[repeat(36,minmax(0,1fr))] place-items-end'>
          <img
            alt='envelope preview'
            src={`.${KIOSK_ASSET_BASE_PATH}/envelopePreview.png`}
            className='col-start-1 col-end-13 row-start-1 row-end-2 w-full'
          />

          <img
            src={coverImageSrc}
            alt={coverImageAlt ?? ''}
            className='col-start-11 col-end-[23] row-start-1 row-end-2 aspect-[5/7] w-full border border-black bg-white p-[4%]'
            height='500'
            width='357'
          />

          <div className='col-start-[20] col-end-[37] row-start-1 row-end-2 grid aspect-[10/7] w-full auto-cols-fr grid-cols-2 border border-black shadow-md'>
            <div className='bg-tonal-10 border-r border-black p-[14.5%]'>
              <div
                className={`${
                  textPosition === 'top' ? '' : 'flex h-full items-center'
                }`}
              >
                <p
                  className={messageClass({
                    usage: 'finalPreview',
                    className: `${
                      textFormat === 'left' ? 'text-left' : 'text-center'
                    }`,
                    variant: fontSelection,
                    size: calcFontSizeVariant(cardMessage),
                  })}
                >
                  {cardMessage ? cardMessage : null}
                </p>
              </div>
            </div>

            <div className='relative grid h-full auto-rows-fr p-[16.16%]'>
              {cardSignatureImg ? (
                <img
                  src={cardSignatureImg}
                  alt='signature'
                  className='z-20 flex h-[40%] flex-col justify-center self-end bg-transparent'
                  style={{ background: 'transparent' }}
                />
              ) : (
                <p
                  className={signatureClass({
                    usage: 'finalPreview',
                    variant: fontSelection,
                    className:
                      'z-20 flex h-[40%] flex-col justify-center self-end',
                  })}
                >
                  {cardSignature ?? null}
                </p>
              )}

              {interiorImageSrc && (
                <img
                  src={interiorImageSrc}
                  alt=''
                  className='absolute left-1/2 top-1/2 z-10 aspect-[5/7] -translate-x-1/2 -translate-y-1/2 object-cover p-[4%]'
                  height='500'
                  width='357'
                />
              )}
              <div
                id='card_interior_shadow'
                className='absolute inset-0 z-20 h-full w-full'
                style={{
                  backgroundSize: 'cover',
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='7' viewBox='0 0 5 7' className='absolute inset-0 z-20 h-full w-full' %3E%3Cpath opacity='0.05' d='M0 0V7H4.5L0 0Z' fill='url(%23card_shadow_diagonal_gradient)' /%3E%3Cdefs%3E%3ClinearGradient id='card_shadow_diagonal_gradient' x1='2.25' y1='0' x2='2.25' y2='7' gradientUnits='userSpaceOnUse' %3E%3Cstop /%3E%3Cstop offset='1' stop-color='%23D9D9D9' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
                }}
              />
            </div>
          </div>
        </div>
        {giftCard ? (
          <div className='web:flex-col kiosk:gap-12 web:gap-2 flex w-full items-center'>
            <GiftCard
              data-cy='Gift Card'
              className='web:max-h-14'
              providerName={giftCard.providerName}
              imgSrc={giftCard.imgSrc}
            />
            <p className='select-none whitespace-pre-line text-sm font-normal'>
              A
              <span className='font-semibold'>
                {` $${giftCard.selectedDenomination} ${giftCard.providerName}`}
              </span>
              {/* {` gift card will be:\n - Printed on the back of the card\n - Sent to your email as a backup`} */}
              {` gift card will be:\n - Printed on the back of the card`}
            </p>
          </div>
        ) : null}
        <div className='flex w-full flex-col'>
          <hr className='border-line w-full border' />
          <p className='text-heading2 self-end' data-cy='Card total'>
            {`$ ${(
              parseFloat(giftCard?.selectedDenomination ?? '0') + cardPrice
            ).toFixed(2)}`}
          </p>
        </div>
      </div>
    </>
  );
};
