import { PersonalizationEvents } from '@inkd/xstate/machines/events';
import { PersonalizationEvent } from '@inkd/xstate/machines/kioskFlows/personalizationFlow';
import type { Sender } from 'xstate';

export const getToggleTextFormatButtons = (
  send: Sender<PersonalizationEvent>
) => {
  const textFormatValues = [
    { name: 'left', icon: 'TextAlignLeft' },
    { name: 'center', icon: 'TextAlignCenter' },
  ] as const;
  const toggleTextFormatButtons = textFormatValues.map(btnValue => ({
    id: `toggle-btn-text-format-${btnValue.name.toLowerCase()}`,
    label: btnValue.name,
    value: btnValue.name,
    icon: btnValue.icon,
    className: '',
    onClick: () =>
      send({
        type: PersonalizationEvents.UPDATE_TEXT_FORMAT,
        textFormat: btnValue.name,
      }),
  }));
  return toggleTextFormatButtons;
};

export const getToggleTextPositionButtons = (
  send: Sender<PersonalizationEvent>
) => {
  const textPositionValues = [
    { name: 'top', icon: 'TextPositionTop' },
    { name: 'center', icon: 'TextPositionCenter' },
  ] as const;
  const toggleTextPositionButtons = textPositionValues.map(btnValue => ({
    id: `toggle-btn-text-position-${btnValue.name.toLowerCase()}`,
    label: btnValue.name,
    value: btnValue.name,
    icon: btnValue.icon,
    className: '',
    onClick: () =>
      send({
        type: PersonalizationEvents.UPDATE_TEXT_POSITION,
        textPosition: btnValue.name,
      }),
  }));
  return toggleTextPositionButtons;
};

export const getToggleButtons = (
  send: Sender<PersonalizationEvent>,
  spanish: boolean
) => {
  // Toggle Button group for use with KioskCardEditor view
  const buttonValues = ['Modern', 'Classic', 'Handwriting'] as const;
  const spanishTranslation = {
    Modern: 'Moderno',
    Classic: 'Clásico',
    Handwriting: 'Escrito',
  };
  /** an array of buttons to be used for the font selector component */
  const toggleButtons = buttonValues.map(btnValue => ({
    id: `toggle-btn-${btnValue.toLowerCase()}`,
    label: spanish ? spanishTranslation[btnValue] : btnValue,
    value: btnValue,
    className: '',
    onClick: () =>
      send({
        type: PersonalizationEvents.UPDATE_FONT,
        fontSelection: btnValue,
      }),
  }));
  return toggleButtons;
};
