import React, { useState } from 'react';
import { type AddonCategoriesQuery } from '@inkd/graphql';
import { Button } from '@inkd/ui';

export interface FiltersProps {
  onClose: () => void;
  filters: AddonCategoriesQuery['categories'] | undefined;
  filterCategories: AddonCategoriesQuery['categories'] | undefined;
  currentFilters: string[];
  setCurrentFilters: React.Dispatch<React.SetStateAction<string[]>>;
  applyBtnStyle?: React.CSSProperties;
  filterHeadingStyle?: React.CSSProperties;
  filterSubHeadingStyle?: React.CSSProperties;
  filterDivStyle?: React.CSSProperties;
  hitAPI?: (recipientFilters: string[]) => void;
  spanish: boolean;
}

export const ShowFilter = ({
  onClose,
  filters,
  filterCategories,
  currentFilters,
  setCurrentFilters,
  applyBtnStyle,
  filterHeadingStyle,
  filterSubHeadingStyle,
  filterDivStyle,
  hitAPI,
  spanish,
}: FiltersProps) => {
  const [currentFiltersLocal, setCurrentFiltersLocal] =
    useState<string[]>(currentFilters);

  const applyHandler = () => {
    if (
      JSON.stringify(currentFiltersLocal.sort()) ===
      JSON.stringify(currentFilters.sort())
    ) {
      onClose();
      return;
    }
    setCurrentFilters(currentFiltersLocal);
    if (hitAPI) hitAPI(currentFiltersLocal);
    onClose();
  };

  return (
    <div
      id='filter'
      style={{
        borderRadius: '1.5rem 0 0 1.5rem',
        borderWidth: '1rem 0 1rem 1rem',
        ...filterDivStyle,
      }}
      className='bg-gradient-purple-to-yellow-clipped height-[32rem] absolute right-4
      z-30 border-transparent bg-origin-border p-6 pb-12 [background-clip:padding-box,border-box]'
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 style={filterHeadingStyle} className='filter_heading'>
          {spanish ? 'Filtros' : 'Filters'}
        </h1>
        <Button
          type='button'
          variant='primary'
          className='after:relative after:content-[]'
          style={{ borderWidth: '0.25rem', ...applyBtnStyle }}
          onClick={applyHandler}
        >
          {spanish ? 'Aplicar' : 'Apply'}
        </Button>
      </div>
      {filterCategories?.map(category => {
        if (!category) {
          return;
        }
        if (
          filters?.filter(filter => filter?.parent?.id === category.id)
            .length === 0
        ) {
          return;
        }
        return (
          <React.Fragment key={category?.id}>
            <p className='my-4 font-bold' style={filterSubHeadingStyle}>
              {category?.categoryName}
            </p>
            <div className='web:gap-2 flex flex-wrap items-center gap-6'></div>
            {filters?.map(filter => {
              if (!filter || filter.parent?.id !== category?.id) {
                return null;
              }
              return (
                <button
                  key={filter.id}
                  className='p-i-8 p-b-3 web:p-i-4 web:p-b-2 border-tonal-60 text-ui-sm text-primary hover:bg-surface-secondary focus-visible:bg-surface-secondary data-[selected=true]:bg-surface-active data-[selected=true]:border-surface-active web:border-2 m-1 rounded-sm border-4 bg-white data-[show-all=false]:[&:nth-child(n+3)]:hidden'
                  data-selected={currentFiltersLocal.includes(filter.id)}
                  onClick={() => {
                    const updatedFilters = currentFiltersLocal.includes(
                      filter.id
                    )
                      ? currentFiltersLocal.filter(f => f !== filter.id)
                      : [...currentFiltersLocal, filter.id];
                    setCurrentFiltersLocal(updatedFilters);
                  }}
                >
                  {spanish && filter.categoryNameSpanish
                    ? filter.categoryNameSpanish
                    : filter.categoryName}
                </button>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};
