import { cx } from 'cva';
import { SVG } from '@inkd/ui';
import {
  ComponentPropsWithoutRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { messageClass, signatureClass, calcFontSizeVariant } from '@inkd/utils';

export type NamedFontType = 'Modern' | 'Classic' | 'Handwriting';
export type NamedTextFormat = 'left' | 'center';
export type NamedTextPosition = 'top' | 'center';

export interface KioskCardEditorProps
  extends Omit<ComponentPropsWithoutRef<'section'>, 'children'> {
  className?: string;
  message?: string;
  /** This method will be called when the user clicks the 'Add message' CTA
   *
   * Given the current designs, this should open the signature editor modal.
   */
  editMessage: () => void;
  signature?: string;
  signatureImg?: string;
  /** This method will be called when the user clicks the 'Add signature' CTA.
   *
   * Given the current designs, this should open the message editor modal.
   */
  editSignature: () => void;
  editSignatureImg: () => void;
  fontSelection: NamedFontType;
  /** if imgSrc isn't provided, the inner image will be omitted */
  imgSrc?: string;
  textFormat: NamedTextFormat;
  textPosition: NamedTextPosition;
  spanish: boolean;
}

/**
 * This component enables a user to initiate the customization of their greeting card,
 * and provides the first 'preview' of what their card will look like once printed.
 *
 * ### Resources
 *
 * - [Component design reference](https://www.figma.com/file/eNHkTSgulGkScTzU1c9O3o/Layouts?type=design&node-id=863-26549&mode=dev)
 * - [Enumerated style combinations](https://www.figma.com/file/eNHkTSgulGkScTzU1c9O3o/Layouts?type=design&node-id=1131-7971&mode=dev)
 */
export const KioskCardEditor = ({
  className,
  message,
  editMessage,
  signature,
  signatureImg,
  editSignature,
  editSignatureImg,
  fontSelection = 'Handwriting',
  imgSrc,
  textFormat,
  textPosition,
  spanish,
  ...sectionProps
}: KioskCardEditorProps) => {
  const messageRef = useRef<HTMLParagraphElement>(null);
  const [textOverflow, setTextOverflow] = useState(false);

  /**
   * To determine if the element is overflowing, we need the `clientHeight`
   * and `scrollHeight` values, which are not available until *after* the
   * first render.
   * This hook forces a double render cycle before changes are painted to the
   * DOM, and the code executes *between* the renders so our element heights
   * are defined and we can correctly assess if text is overflowing.
   */
  useLayoutEffect(() => {
    /**
     * For some reason - exclusively with the Handwriting font - the scroll
     * height is *always* about 3 pixels more than the client height.
     *
     * My current hypothesis is that the descenders (the portion of characters
     * like "j", "g", etc that extend below the baseline) are causing this.
     *
     * In any case, if we subtract the scrollHeight by 5 we get the desired
     * outcome. Don't love it, but 🤷🏼‍♂️
     */
    const fontDescenderPixelHeight = 5;

    if (
      messageRef.current?.clientHeight &&
      messageRef.current?.scrollHeight &&
      messageRef.current.scrollHeight - fontDescenderPixelHeight >
        messageRef.current.clientHeight
    ) {
      setTextOverflow(true);
    }

    return () => setTextOverflow(false);
  }, [message, fontSelection]);

  return (
    <section
      data-message-overflow={textOverflow}
      className={cx(
        'data-[message-overflow=true]:-m-be-12 flex flex-col gap-6',
        className
      )}
      {...sectionProps}
    >
      <div className='border-image-outline grid w-full auto-cols-fr grid-cols-2 border border-opacity-10 shadow-[0px_80px_80px_-80px_rgba(0,0,0,0.20),_0px_2px_4px_0px_rgba(0,0,0,0.10)]'>
        <div
          /**
           * padding note:
           * - we're using percentages to better match the designs (which are calculated off the width)
           * - the print margin is 12 postscript points (~0.1667 inches), which is ~3.33% of the width of the card.
           */
          className='bg-tonal-10 border-image-outline border-ie aspect-[5/7] h-full border-opacity-10 p-[3.33%]'
        >
          <button
            data-message-overflow={textOverflow}
            data-cy='Message field'
            onClick={editMessage}
            className={cx(
              'border-tonal-60 data-[message-overflow=true]:border-error relative flex h-full w-full flex-col rounded-md border-4 border-dashed',
              textPosition === 'top' ? 'justify-start' : 'justify-center'
            )}
          >
            <p
              ref={messageRef}
              data-message={!!message}
              className={cx(
                messageClass({
                  usage: 'cardEditor',
                  className:
                    /**
                     * padding note:
                     * - the absolute padding percentage (from the edge of the page) should be 16.16%
                     * - because of the 3.33% padding from the grandparent element, the padding we will apply here is 16.72%
                     * - ALSO the max height should be 76.88% relative to the page size, but after factoring in the 3.33% we really need to apply a max height of 79.53%
                     */
                    'h-fit max-h-[79.53%] max-w-full overflow-hidden p-[12%] data-[message=false]:text-center',
                  variant: fontSelection,
                  size: calcFontSizeVariant(message),
                }),
                textFormat === 'center' ? 'text-center' : 'text-left'
              )}
              style={{ wordBreak: 'break-word' }}
            >
              {message ? message : spanish ? 'Escribir mensaje' : 'Add message'}
            </p>
            <div className='border-tonal-60 absolute bottom-4 right-4 rounded-sm border-4 p-3'>
              <SVG name='Pencil' className='h-8 w-8' />
            </div>
          </button>
        </div>
        <div
          className='relative aspect-[5/7] h-full w-full p-[3%]'
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'repeat(3, 1fr)',
          }}
        >
          <div
            data-cy='Signature field'
            className='border-tonal-60 p-i-6 z-30 flex h-52 max-h-52 w-full resize-none flex-col justify-between rounded-md border-4 border-dashed
            bg-transparent py-5 text-lg'
            style={
              signatureImg
                ? {
                    gridArea: '3/1/4/1',
                    backgroundImage: `url(${signatureImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '225px',
                    backgroundPosition: 'center 35%',
                  }
                : { gridArea: '3/1/4/1' }
            }
          >
            <p
              className={signatureClass({
                usage: 'cardEditor',
                variant: signature ? fontSelection : 'Normal',
              })}
            >
              {signatureImg?.length
                ? ''
                : signature
                ? signature
                : spanish
                ? 'Firma'
                : 'Signature'}
            </p>
            <div className='flex justify-around'>
              <button
                className='bottom-4 right-4 basis-5/12 rounded-sm border-2 border-black p-3 font-semibold'
                style={{ backgroundColor: '#d3d3d330' }}
                onClick={editSignatureImg}
              >
                {spanish ? 'Firmar' : 'Sign'}
              </button>
              <button
                className='bottom-4 right-4 basis-5/12 rounded-sm border-2 border-black p-3 font-semibold'
                style={{ backgroundColor: '#d3d3d330' }}
                onClick={editSignature}
              >
                {spanish ? 'Escribir' : 'Type'}
              </button>
            </div>
          </div>
          {
            // While not explicitly mentioned as a possibility, I imagine a card _without_ an interior image (blank interior) could be a desireable product
            // in the absence of the imgSrc prop, we will simply omit the <img> tag
            imgSrc && (
              <img
                src={imgSrc}
                alt=''
                className='z-10 h-full object-cover'
                style={{ gridArea: '1/1/4/2' }}
              />
            )
          }
          <div
            id='card_interior_shadow'
            className='absolute inset-0 z-20'
            style={{
              backgroundSize: 'cover',
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='7' viewBox='0 0 5 7' className='absolute inset-0 z-20 h-full w-full' %3E%3Cpath opacity='0.05' d='M0 0V7H4.5L0 0Z' fill='url(%23card_shadow_diagonal_gradient)' /%3E%3Cdefs%3E%3ClinearGradient id='card_shadow_diagonal_gradient' x1='2.25' y1='0' x2='2.25' y2='7' gradientUnits='userSpaceOnUse' %3E%3Cstop /%3E%3Cstop offset='1' stop-color='%23D9D9D9' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
            }}
          />
        </div>
      </div>
      {textOverflow ? (
        <span className='flex gap-6'>
          <SVG className='text-error h-10 w-10 flex-shrink-0' name='Error' />
          <span className='text-primary text-sm text-inherit'>
            {spanish
              ? '¡Ups! Parece que tu mensaje es un poco demasiado largo. Por favor, acórtalo para asegurar que se imprima correctamente.'
              : 'Oops! It looks like your message is a little bit too long. Please shorten it to ensure it prints correctly.'}
          </span>
        </span>
      ) : null}
    </section>
  );
};
