import { type ShoppingCart } from '@inkd/xstate';
import { CARD_PRICE } from './cardPrice';

/**
 * Reducer function that takes the cart and returns the subtotal for the Checkout cart page. Works with both XState cart for kiosk and LocalStorage cart for web/mobile.
 */
export function getCartSubtotal(
  cart: ShoppingCart,
  includeMarkedForDeletion: boolean = true
): string {
  return cart
    .reduce((runningTotal, cartItem) => {
      // if we have an invalid quantity, don't count it in the running total
      if (typeof cartItem.quantity !== 'number') {
        return runningTotal;
      }
      // if we are about to delete the card, don't count it in the running total
      if (cartItem.markedForDeletion && !includeMarkedForDeletion) {
        return runningTotal;
      }

      // calculate the total for this card depending on whether or not it has a gift card attached to it
      if (typeof cartItem.giftCardPrice === 'number') {
        return (
          (CARD_PRICE + cartItem.giftCardPrice) * cartItem.quantity +
          runningTotal
        );
      }
      return CARD_PRICE * cartItem.quantity + runningTotal;
    }, 0)
    .toFixed(2);
}
